@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');
/* --------------------------------------------------------------

   typography.css
   * Sets up some sensible default typography.

-------------------------------------------------------------- */

  
  /* Headings
  -------------------------------------------------------------- */
  
  h1,h2,h3,h4,h5,h6 { font-weight: normal; color: #111; }
  
  h1 { font-size: 3em; line-height: 1; margin-bottom: 0.5em; color: #001A32;}
  h2 { font-size: 2em; margin-bottom: 0.75em; }
  h3 { font-size: 1.5em; line-height: 1; margin-bottom: 1em; }
  h4 { font-size: 1.2em; line-height: 1.25; margin-bottom: 1.25em; }
  h5 { font-size: 1em; font-weight: bold; margin-bottom: 1.5em; }
  h6 { font-size: 1em; font-weight: bold; }
  
  h1 img, h2 img, h3 img,
  h4 img, h5 img, h6 img {
    margin: 0;
  }
  
  /* Text elements
  -------------------------------------------------------------- */
  
  p           { margin: 0 0 1.5em; }
  p img.left  { float: left; margin: 1.5em 1.5em 1.5em 0; padding: 0; }
  p img.right { float: right; margin: 1.5em 0 1.5em 1.5em; }
  
  a:focus,
  a:hover     { color: #009; }
  a           { color: #000; text-decoration: underline; }
  
  blockquote  { margin: 1.5em; color: #666; font-style: italic; }
  strong      { font-weight: bold; }
  em,dfn      { font-style: italic; }
  dfn         { font-weight: bold; }
  sup, sub    { line-height: 0; }
  
  abbr,
  acronym     { border-bottom: 1px dotted #666; }
  address     { margin: 0 0 1.5em; font-style: italic; }
  del         { color:#666; }
  
  pre 				{ margin: 1.5em 0; white-space: pre; }
  pre,code,tt { font: 1em 'andale mono', 'lucida console', monospace; line-height: 1.5; }
  
  /* Lists
  -------------------------------------------------------------- */
  
  li ul,
  li ol       { margin:0 1.5em; }
  ul, ol      { margin: 0 1.5em 1.5em 1.5em; }
  
  ul          { list-style-type: disc; }
  ol          { list-style-type: decimal; }
  
  dl          { margin: 0 0 1.5em 0; }
  dl dt       { font-weight: bold; }
  dd          { margin-left: 1.5em;}
  
  /* Tables
  -------------------------------------------------------------- */
  
  table       { margin-bottom: 1.4em; width:100%; }
  th          { font-weight: bold; }
  thead th 		{ background: #c3d9ff; }
  th,td,caption { padding: 4px 10px 4px 5px; }
  tr.even td  { background: #e5ecf9; }
  tfoot       { font-style: italic; }
  caption     { background: #eee; }
  
  /* Misc classes
  -------------------------------------------------------------- */
  
  .small      { font-size: .8em; margin-bottom: 1.875em; line-height: 1.875em; }
  .large      { font-size: 1.2em; line-height: 2.5em; margin-bottom: 1.25em; }
  .hide       { display: none; }
  
  .quiet      { color: #666; }
  .loud       { color: #000; }
  .highlight  { background:#ff0; }
  .added      { background:#060; color: #fff; }
  .removed    { background:#900; color: #fff; }
  
  .first      { margin-left:0; padding-left:0; }
  .last       { margin-right:0; padding-right:0; }
  .top        { margin-top:0; padding-top:0; }
  .bottom     { margin-bottom:0; padding-bottom:0; }

  /* Costum classes
  -------------------------------------------------------------- */
  .mainColor{
    color:#003363 !important;
  }
  .mainBgColor{
    background-color: #F7F9FC!important;
  }
  .mainBG{
  background-image: url(https://www.pixelstalk.net/wp-content/uploads/2016/06/Minimal-Backgrounds-For-Desktop.jpeg) !important;
  background-size: 100%;
  }
  
  .blurBG{
    -webkit-backdrop-filter: blur(.8em);
    backdrop-filter: blur(.8em);
    border-radius: 5% !important;
  }
  .btn-primary {
    background-color: #0044AB;
    color: #ffffff;
    border: none;
    border-radius: 32px;
    cursor: pointer;
  }
  .btn-primary:hover {
    background-color: #fff !important;
    color: #0044AB;
  }
  .btn-primary:focus {
    background-color: #0044AB !important;
    color: #fff;
  }
  .form-control {
    border: 1px solid #CCD9E5;
    border-radius: 30px;
  }
  .form-control:hover{
    border: 1px solid #0044AB;
    box-shadow: none;
  }
 
  /* User list
  -------------------------------------------------------------- */
  .userList .h6 {
    color: #0044AB;
  }
  
  /* DynamicTable */
  .dynamic-table-container {
    width: 100%; /* Set the desired width for the table container */
    overflow-x: auto; /* Enable horizontal scrolling */

  }
.dynamic-table {
  border-radius: 10px 10px 0px 0px;
  overflow: hidden; /* To ensure the border-radius is applied correctly */
  width: 100%; /* Set the table width to 100% */
  border-collapse: collapse; /* Collapse table borders */
  /* Add any other styling as needed */
}
.dynamic-table thead > tr > th {
  background-color: #668FCD !important;
  color: white;
  padding: 15px !important;
  font-family: 'Outfit';
  font-size: 10spx !important;
  font-weight: 400;
}
.dynamic-table tbody > tr  > td{
  padding: 15px !important;
  font-family: 'Outfit';
  font-size: 15px !important;
  font-weight: 500;
  white-space: nowrap; /* Ensure text doesn't wrap to the next line */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Display an ellipsis (...) when text overflows */
  /* Add any other styling as needed */
}
.CustomPagination{
  border: 0px !important;
  color: #0044AB !important;
}
.CustomPagination:hover{
color: white !important;
}

.page-tabs {
  display: flex;
}

.page-tab {
  cursor: pointer;
  padding: 8px 12px;
  margin: 0 5px;
  border-radius: 4px;
}

.page-tab.active {
  background-color: #fff;
  color: #001A32;
  border: 1px solid #CCDAEE;
}
.thinner-icon {
  font-weight:lighter;
}

.custom-search {
  position: relative;
  width: 100%;

}
.custom-search-input {
  width: 100%;
  border: 1px solid #F2F5F8;
  border-radius: 30px;
  padding: 10px !important;
  box-sizing: border-box;
  outline: none;
}
.custom-search-input:hover {
  border: 1px solid #0044AB;
}
.custom-search-input::-webkit-input-placeholder {
  color: #668CB0;
  padding-left: 10px;
}

.custom-search-input:-moz-placeholder {
  color: #668CB0;
  padding-left: 10px;
}
.custom-search-input[type=text] {
  padding: 12px 20px !important;
}
.custom-search-botton {
  position: absolute;
  right: 3px; 
  top: 3px;
  bottom: 3px;
  border: 0;
  background: #0044AB;
  color: #fff;
  outline: none;
  margin: 0;
  padding: 0 20px;
  border-radius: 100px;
  z-index: 2;
}
.search-with-filtre {
  position: absolute;
 /* right: 3px; 
  top: 3px;
  bottom: 3px; */
  border: 0;
  background: #0044AB;
  color: #fff;
  outline: none;
  margin: 0;
  padding: 0 20px;
  border-radius: 100px;
  z-index: 2;
}
.UserTypeDropdown{
  background-color: transparent !important;
  color: #000 !important;
}
.UserTypeDropdown:hover{
  background-color: #0044AB !important;
  color: #fff !important;
}

.LongText{
  width: 150px !important;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.TrashSize{
width: 24px;
}
.card .form-control {
  background-color: #F7F9FC;
  border: 1px solid #CCD9E5;
  color: #000; /* Optionally set text color for better visibility */
}

.card .form-control:hover {
  border: 1px solid #0044AB;
}

.mapItem{
  box-sizing: border-box;
  padding: 10px 10px 10px 30px;
  font-size:15px;
  border-radius: 30px;
}
.mapItem:hover{
background-color: #0044AB !important;
color: #FFF;
cursor:pointer
}
.stateDate{
  font-size: 16px;
}
.main-content.col {
  min-height: 100vh;
  height: auto !important;
}

/* CTA connecxion */
.btn-cnx{
  font-family: Outfit;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.64px;
  text-align: right;
  text-decoration: none;
  color: rgba(0, 68, 171, 1);
}
/* Change Password */
.style-h5{
font-family: Outfit;
font-size: 32px;
font-weight: 700;
line-height: 40.32px;
text-align: left;
margin-bottom:16px;
}
.margin-32{
  margin-bottom:32px;
}
.code-input{
  border-radius: 8px !important;
  width: 48px;
height: 48px;
gap: 0px;
border-radius: 8px 0px 0px 0px;
border: 2px 0px 0px 0px;
border: 1px solid #ccd9e5;
}
.text-code-confirm{
font-family: Outfit;
font-size: 16px;
font-weight: 400;
line-height: 20.16px;
text-align: left;
}
.code-label{
color: #99b3cb !important;
font-family: Outfit;
font-size: 16px;
font-weight: 400;
line-height: 20.16px;
text-align: left;
}
.password-input-container .password-icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
}
.colorBlue{
  color: rgba(0, 51, 99, 1);
}
.pwd-icon{
  position: absolute;
  top: 46%;
  right: 3rem;
  transform: translateY(-50%);
  cursor: pointer;
}
/* Dynamic Table */
.dynamic-style{
  overflow-x: auto;
  white-space: nowrap;
}
.dynamic-th{
  position: sticky;
  right: 0;
  background: white;
}
/* Calender Style */
.react-datepicker__footer{
  padding:20px!important;
  text-align: right!important;
}
.margin-date{
  margin-right: 10px;
}
.react-datepicker__header{
background-color: white!important;
border-radius:24px!important;
border-bottom: 0px!important;

}
.react-datepicker__day-names{
  padding-top: 15px!important;
}
.react-datepicker{
  border-radius:24px!important;
/* border-color:rgba(63, 63, 63, 0.08); */
}
.react-datepicker__navigation-icon::before{
  border-color: rgba(0, 68, 171, 1)!important;
}
/*Dynamic Table*/
.dynamic-style{
  overflow-x: auto;
  white-space: nowrap;
}
.dynamic-th{
  position: sticky;
  right: 0;
  background: white;
}
.positionPagination{
 position: absolute !important;
  padding-Top: 88px !important;
}
.container-autocomplete {
  position: absolute;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ddd;
  background-color: white;
}
.container-adr{
  width: 70%;
}
label{
  font-size: medium;
}
p{
  font-size: medium;
}
h5{
font-family: Outfit;
font-size: 32px;
font-weight: 700;
line-height: 40.32px;
text-align: center;
margin-bottom: 16px !important;
}
.textConfirmation{
font-family: Outfit;
font-size: 20px;
font-weight: 500;
line-height: 25.2px;
text-align: center;
color:rgba(0, 51, 99, 1);
}
.colormain{
  color: #0044AB;
}
.mt-10{
  margin-bottom: 10px;
}

.mt-41{
  margin-top: 41px;
}
/* search bar */
.search-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 8px; /* Espace entre l'input et le bouton */
}

 .search-input-border {
  border: 0px !important;
}

.form-control:focus {
  box-shadow: none !important;
}
.search-with-filtre {
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}


