/* Sidebar.css */

/* Importing Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');

/* Default styles for larger screens */
.sidebar {
  height: 100vh;
  position: fixed;
  top: 0;
  left: -220px;
  background-color: #fff;
  transition: all 0.3s;
  z-index: 2; /* Set a higher z-index value for the sidebar */

  padding-left: 0 !important;
}

.sidebar.active {
  left: 0;
}

.main-content {
  padding: 20px !important;
  transition: margin-left 0.3s;
  z-index: 1; /* Set a lower z-index value for the main content */
}

.main-content.active {
  margin-left: 241px;
}

.toggle-btn {
  position: absolute;
  bottom: 50%;
  left: 100%;
  background-color: transparent !important;
  color: #001A32 !important;
  padding: 5px;
}
.toggle-btn:hover{
  background-color: transparent !important;
}

/* Media query for smaller screens (e.g., tablets and below) */
@media (max-width: 992px) {
  .sidebar {
    left: -100%;
  }

  .sidebar.active {
    left: 0;
  }

  .main-content.active {
    margin-left: 0;
  }
}

.sidebar-item {
  padding: 20px !important;
  transition: all 0.25s ease-in-out;
  border-radius: 0px 10px 10px 0px;
  color: #001A32 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-arrow {
  margin-right: 10px;
}
.sidebar-item:hover {
  cursor: pointer;
}


.sidebar-item.active {
  background: #F7F9FC;
  border-left: 7px solid #0044AB;
  border-radius: 0px 10px 10px 0px;
}

.sidebar-item p {
  color: #001A32;
  font-family: 'Outfit';
  font-weight: 400;
  font-size: 16px;
  line-height: 20.16px;
}

.sidebar a {
  color: #001A32;
  text-decoration: none !important;
  font-family: 'Outfit';
}

.myLogo {
  max-width: 70%;
  padding: 40px;
  transition: opacity 0.3s ease;
}

.CutomeSideBarIcon {
  padding-right: 10px;
  width: 35px;
}

.sidebar h4 {
  margin-bottom: 0 !important;
  padding: 4px;
}
.sidebar h5 {
  margin-bottom: 0 !important;
  padding: 4px;
  font-size :14px !important;
}

.sidebar .nav-link {
  padding: 0 !important;
  font-weight: 900 !important;
}
.blurBG{
  -webkit-backdrop-filter: blur(.8em);
  backdrop-filter: blur(.8em);
  border-radius: 5% !important;
}
.sidePadding{
 padding: 0px !important;
}