@font-face {
  font-family: "Abhaya";
  src: local("GoldmanBold"),
   url("./style/font/AbhayaLibreMedium.ttf") format("truetype");
 }
 @import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');

/* Other CSS rules below... */
body {
  font-size: 75%;
  margin: 0;
  padding: 0;
  color: #222;
  background: #fff;
  font-family: 'Outfit', "Helvetica Neue", Arial, Helvetica, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


p {
  margin: 0;
}
.scrollable-container {
  height: 600px; /* Set a fixed height or max-height */
  overflow-y: auto; /* Add vertical scrollbar */
}
.link-style{
  text-decoration: none;
}




