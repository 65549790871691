/* stylesheet de la commande fournisseur */

.title-style {
  font-family: Outfit;
  font-size: 40px;
  font-weight: 700;
  line-height: 50.4px;
  text-align: left;
  color: rgba(0, 51, 99, 1);
  margin-bottom: 16px !important;
}
.layout {
  display: flex;
  align-items: center;
  border-radius: 16px;
  border: 2px;
  background: rgba(255, 244, 243, 1);
  border: 2px solid rgba(255, 165, 160, 1);
  padding: 16px;
  font-size: 14px;
}

.iconAlert {
  width: 20px; /* Adjust the size of the alert icon */
  height: 20px;
}

.text-container {
  font-family: Outfit;
  font-size: 18px;
  font-weight: 500;
  line-height: 22.68px;
  text-align: left;
}

.alert-message {
  margin: 10px 0; /* Add some space above and below the message */
}
/* card */
.textCard {
  font-family: Outfit;
  font-size: 17px;
  font-weight: 600;
  line-height: 22.68px;
  text-align: left;
}
.backWhite {
  background-color: #fff !important;
}
/* bouton*/
.btn-primaryStyle {
  padding: 18px 24px 18px 24px;
  border-radius: 32px;
}
.colorInput {
  color: rgba(153, 179, 203, 1) !important;
}

/* File upload */
.file-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d9d9d9;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
}

.file-info {
  display: flex;
  align-items: center;
}

.file-icon {
  margin-right: 10px;
}

.file-details {
  display: flex;
  flex-direction: column;
}
.file-Title {
  font-family: Outfit;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.16px;
  text-align: left;
}
.file-upload-button {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border-radius: 32px;
  cursor: pointer;
  color: #0056b3;
  border-color: #0056b3 !important;
  border: 2px solid;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.file-upload-button img {
  margin-right: 8px;
}

.file-input {
  display: none; /* Hide the default file input */
}

.error-message {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.error-message img {
  margin-right: 8px;
}
/* btn-logout */
.logoutBtn {
  background-color: #fff !important;
  color: rgba(0, 68, 171, 1);
  border: 0px;
  font-family: Outfit;
  font-size: 18px;
  font-weight: 500;
  line-height: 22.68px;
  text-align: center;
}
/* structure demande page */

.left-side {
  background-color: rgba(51, 102, 150, 1);
  display: flex;
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
}
.right-side {
  padding: 20px 40px!important;
}
.demandeFournisseur {
  max-width: 100%;
  height: auto;
}
.headerSection {
  padding: 20px 40px;
}
.colorTitle {
  color: rgba(0, 64, 124, 1);
}

.cardDemandeFo {
  border: 0px;
}
.PageFournisseur .react-datepicker-wrapper {
  display: block!important;
}
.PageFournisseur .react-datepicker__input-container input {
  height: 58px!important;
}
.PageFournisseur .react-datepicker-wrapper > label{
  height: auto!important;
}

.PageFournisseur .react-datepicker__input-container input{
  background: url('../../src/images/fi_calendar.png');
  content: "";
  background-repeat: no-repeat;
  background-position: right 3em bottom 10px;
  padding-top: 34px!important;
  cursor: pointer;
}
.sizeInput{
  font-size:13px!important;
}
.react-datepicker-popper {
  z-index: 9999;
}
.PageFournisseur  .react-datepicker {
  border-radius: 1px !important;
}